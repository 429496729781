import { Box, Button, TextField, Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import SearchIcon from '@material-ui/icons/Search';
import classnames from 'classnames';
import { graphql, Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import ContactForm from '../../Common/ContactForm';
import Layout from '../../Common/Layout';
import ScrollTo from '../../Common/ScrollTo';
import ActusItem from './ActusItem';
import { UseCustomQuery } from './blogQuery';
//import useFlexSearch from 'react-use-flexsearch';

import styles from './style';

interface IBlogPagination {
  data: any;
  pageInfo: any;
}

const Blog = (props: IBlogPagination) => {
  const { data, pageInfo } = props;
  const allPost = UseCustomQuery();

  const classes = styles();
  const datas = data.allWpPost.edges;

  const [posts, setPosts] = useState(datas);
  const [value, setValue] = useState('');
  //const results = useFlexSearch(value, index, store);
  //console.log('result:', results);

  const handleDelete = () => {
    setPosts(datas);
    setValue('');
  };
  const handleRemove = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!value) {
      setPosts(datas);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    const regex = new RegExp(event.target.value, 'ig');

    setPosts(() => {
      return allPost.filter(({ node }: any) => {
        return regex.test(node.slug.replace(/-/gi, ' '));
      });
    });
  };
  const [showScrollTo, setShowScrollTo] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    setShowScrollTo(offset > 1000);
  };
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const domaine_ext = url.includes(".fr");
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Box className={classes.actualites} id="actu">
      {showScrollTo ? <ScrollTo element="/#actu" /> : null}
      <Box className={classes.topBox}>
        <Box className={classes.background} />
        <Layout>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="h1"
              className={classnames('titreSection', classes.titrePage)}
            >
              Nos actualités
            </Typography>
            <Typography
              className={classnames('textParagraphe', classes.descriptionPage)}
            >
              Découvrez les actus de Hairun Technology, des astuces et pleins
              d'autres items autour de la technologie et du digital.
            </Typography>

            <Box className={classes.formSearch}>
              <TextField
                placeholder="Rechercher un article..."
                onChange={handleSearch}
                onKeyUp={handleRemove}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Layout>
      </Box>
      <Layout>
        <Box className={classnames(classes.mansory, classes.listing)}>
          {posts.length ? (
            posts.map(({ node }: any, index: number) => {
              //if (index < 2) {
              const image = node.featuredImage
                ? node.featuredImage.node.sourceUrl
                : '';
              return (
                <ActusItem
                  key={index}
                  title={node.title}
                  image={image}
                  intro={node.excerpt}
                  date={node.date}
                  url={`/blog/${node.slug}`}
                />
              );
              //  }
            })
          ) : (
            <Typography
              style={{
                marginLeft: '50%',
                marginRight: '50%',
                minWidth: 150,
              }}
            >
              {'Aucun résultat'}
            </Typography>
          )}
        </Box>
        {/* PAGINATION */}
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          className={classes.pagination}
        >
          <Box component="span">
            {pageInfo ? pageInfo.humanPageNumber : 'null'}-
            {pageInfo ? pageInfo.numberOfPages : 'null'} sur{' '}
            {pageInfo ? pageInfo.numberOfPages : 'null'}
          </Box>
          <Button disabled={pageInfo && pageInfo.previousPagePath === ''}>
            <Link to={'/blog'}>
              <FirstPageIcon />
            </Link>
          </Button>
          <Button disabled={pageInfo && pageInfo.nextPagePath === ''}>
            <Link to={`/blog/${pageInfo.numberOfPages}`}>
              <LastPageIcon />
            </Link>
          </Button>
          <Button disabled={pageInfo && pageInfo.previousPagePath === ''}>
            <Link to={pageInfo.previousPagePath}>
              {' '}
              <ChevronLeftIcon />
            </Link>
          </Button>
          <Button disabled={pageInfo && pageInfo.nextPagePath === ''}>
            <Link to={pageInfo.nextPagePath}>
              {' '}
              <ChevronRightIcon />
            </Link>
          </Button>
        </Box>
      </Layout>
      {!domaine_ext ?
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.com"
          formTitle="Contactez-<span>nous</span>"
        />
        :
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.fr"
          formTitle="Contactez-<span>nous</span>"
        />}
    </Box>
  );
};

export default Blog;
