import React from 'react';
import ArrowRight from '@material-ui/icons/ArrowRightAlt';
import { Paper, Box, Typography, Button, Divider } from '@material-ui/core';
import { TransitionLink as Link } from '../../../Common/TransitionLink/TransitionLink';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import styles from './style';

import ReactHtmlParser from 'react-html-parser';

interface PropTypes {
  image: string;
  key: number;
  title: string;
  intro: string;
  url?: string;
  date?: string;
}

const ActusItem = ({ image, key, title, intro, url, date }: PropTypes) => {
  const classes = styles({ image });

  return (
    <Paper elevation={3} className={classes.paper} key={key}>
      <Link to={url} title={title.replace(/&nbsp;/g, ' ')}>
        <Box className={classes.actusContent}>
          <Box display="flex" alignItems="center" className={classes.meta}>
            <span>Publié</span>
            <CalendarTodayIcon />
            {date}
          </Box>
          <Typography variant="h2" className={classes.textElipsis}>
            {title.replace(/&nbsp;/g, ' ')}
          </Typography>
          <Box className={classes.imgActus}>
            <img src={image} alt=" images Actus" />
          </Box>
          <Box
            dangerouslySetInnerHTML={{ __html: intro }}
            className={classes.textElipsis}
          />
          <Divider className={classes.divider} />
        </Box>
      </Link>
    </Paper>
  );
};

export default ActusItem;
