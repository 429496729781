import { useStaticQuery, graphql } from 'gatsby';

export const UseCustomQuery = () => {
  const data: {
    allWpPost: {
      edges: [
        {
          node: {
            id: string;
            title: string;
            excerpt: string;
            slug: string;
            date: string;
            featuredImage: {
              node: {
                sourceUrl: string;
              };
            };
          };
        },
      ];
    };
  } = useStaticQuery(graphql`
    query($order: [SortOrderEnum] = DESC, $fields: [WpPostFieldsEnum] = date) {
      allWpPost(sort: { order: $order, fields: $fields }) {
        edges {
          node {
            title
            excerpt
            slug
            date(formatString: "DD MMMM yyyy", locale: "fr-FR")
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `);
  return data.allWpPost.edges;
};
