import React from 'react';

import { graphql } from 'gatsby';
import Container from '../../components/Container';
import SEO from '../../components/seo';
import Blog from '../../components/PagesComponent/Blog/Blog';

const BlogPage = (props: any) => {
  return (
    <Container>
      <SEO
        title="Nos actualités"
        description="Découvrez les actus de Hairun Technology, des astuces et pleins d'autres items autour de la technologie et du digital"
      />
      <Blog data={props.data} pageInfo={props.pathContext} />
    </Container>
  );
};
export const pageQuery = graphql`
  query(
    $order: [SortOrderEnum] = DESC
    $fields: [WpPostFieldsEnum] = date
    $skip: Int!
    $limit: Int!
  ) {
    allWpPost(
      sort: { order: $order, fields: $fields }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          title
          excerpt
          slug
          date(formatString: "DD MMMM yyyy", locale: "fr-FR")
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;
export default BlogPage;
